
import { mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import { useCustomer } from '@/stores/customer'
export default {
  name: 'Guest',
  computed: {
    isCheckout() {
      return this.$route.name?.includes('checkout')
    },
    showTextSignup() {
      return (!this.$device.isMobile && this.isCheckout) || !this.isCheckout
    },
  },
  methods: {
    ...mapActions(useCustomer, ['setPopupGuestCheckoutAuth']),
    toAuthentication() {
      if (
        this.$route.name?.includes('checkout') &&
        !['/ru/checkout/success', '/checkout/success']?.includes(
          this.$route.path,
        )
      ) {
        this.setPopupGuestCheckoutAuth(true)
      } else {
        this.$router.push({
          path: this.localePath('auth-authentication'),
        })
      }
    },
  },
}
